import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 10em 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NotFound = ({
    data
}) => {
    return (
        <Wrapper>
            <h1>Oeps sorry!</h1>
            <p>Deze pagina bestaat niet..</p>
        </Wrapper>
    )
}

export default NotFound